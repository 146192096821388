import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { GET_ALL_PANLES, DELETE_PANLE , ADD_PANLES , GET_HISTORY ,EDIT_PANLES , UPDATE_USERACTIVE_STATUS , PANLE_TYPE , UPDATE_ADMIN_INFO,history1,historybyname,Addlead ,
  Getallleads , Updateleaduser , Deleteleadsuser , Getleadhistory , GetperUserleads
} from "../../../Service/Panles.service";




export const Get_All_Panles = createAsyncThunk("/panle/getall", async (data) => {

  try {
    const res = await GET_ALL_PANLES(data);
    return await res;
  } catch (err) {
    return err;
  }
});



export const DeletePanle = createAsyncThunk("/panle/delete", async (data) => {

  try {
    const res = await DELETE_PANLE(data);
    return await res;
  } catch (err) {
    return err;
  }
});



export const AddPanles = createAsyncThunk("/add/panle", async (data) => {

  try {
    const res = await ADD_PANLES(data);
    return await res;
  } catch (err) {
    return err;
  }
});

export const EditPanles = createAsyncThunk("/panle/edit", async (data) => {

  try {
    const res = await EDIT_PANLES(data);
    return await res;
  } catch (err) {
    return err;
  }
});

export const Get_History = createAsyncThunk("/history/getall", async (data) => {

  try {
    const res = await GET_HISTORY(data);
    return await res;
  } catch (err) {
    return err;
  }
});

export const Panle_Type = createAsyncThunk("/panle/type", async (data) => {

  try {
    const res = await PANLE_TYPE(data);
    return await res;
  } catch (err) {
    return err;
  }
});

// UPDATE USER ACTIVE STATUS FOR API
export const UPDATE_USER_ACTIVE_STATUS = createAsyncThunk("update/useractive/status", async (data) => {
  try {
    const res = await UPDATE_USERACTIVE_STATUS(data);
    return await res;
  } catch (err) {
    return err;
  }
});

export const UpdateAdminInformation = createAsyncThunk("/update/admininfo", async (data) => {

  try {
    const res = await UPDATE_ADMIN_INFO(data);
    return await res;
  } catch (err) {
    return err;
  }
});



// pagination

export const get_history1 = createAsyncThunk("/history1/getall", async (data) => {

  try {
    const res = await history1(data);
    return await res;
  } catch (err) {
    return err;
  }
});

 

// historybyid

export const HistoryByPanelname = createAsyncThunk("/history1/historybyPanlename", async (data) => {

  try {
    const res = await historybyname(data);
    return await res;
  } catch (err) {
    return err;
  }
});


export const Addleads = createAsyncThunk("/add/lead", async (data) => {

  try {
    const res = await Addlead(data);
    return await res;
  } catch (err) {
    return err;
  }
});

 
export const GetAllLeads = createAsyncThunk("/geAllLeads", async (data) => {

  try {
    const res = await Getallleads(data);
    return await res;
  } catch (err) {
    return err;
  }
});

  
// update lead user


export const UpdateLeadUser = createAsyncThunk("/updateLeadUser", async (data) => {

  try {
    const res = await Updateleaduser(data);
    return await res;
  } catch (err) {
    return err;
  }
});


// delete lead user 

export const DeleteLeadsUser = createAsyncThunk("/DeleteLeadUser", async (data) => {

  try {
    const res = await Deleteleadsuser(data);
    return await res;
  } catch (err) {
    return err;
  }
});


// get leads history


export const GetLeadsHistory = createAsyncThunk("/getLeadshistory", async (data) => {

  try {
    const res = await Getleadhistory(data);
    return await res;
  } catch (err) {
    return err;
  }
});

// per user leads history 


export const GetPerUserHistory = createAsyncThunk("/getPerUserhistory", async (data) => {

  try {
    const res = await GetperUserleads(data);
    return await res;
  } catch (err) {
    return err;
  }
});



const AllPanles = createSlice({
  name: "AllPanles",
  initialState: {
    isLoading: false,
    isError: false,
    get_All_Panles: [],
    deletePanle: [],
    addPanles:[],
    get_History:[],
    editPanles:[],
    updateStatus:[],
    panle_Type:[],
    updateAdminInformation:[],
    get_history1:[],
    HistoryByPanelname:[],
    Addlead:[],
    GetAllLeads:[],
    UpdateLeadUser:[],
    DeleteLeadsUser:[],
    GetLeadsHistory:[],
    GetPerUserHistory:[]
  },

  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Get_All_Panles.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(Get_All_Panles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.get_All_Panles = action.payload;
      })
      .addCase(Get_All_Panles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(DeletePanle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deletePanle = action.payload;
      })
      .addCase(AddPanles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.addPanles = action.payload;
      })
      .addCase(Get_History.fulfilled, (state, action) => {
        state.isLoading = false;
        state.get_History = action.payload;
      })
      .addCase(EditPanles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editPanles = action.payload;
      })
      .addCase(UPDATE_USER_ACTIVE_STATUS.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateStatus = action.payload;
      })
      .addCase(Panle_Type.fulfilled, (state, action) => {
        state.isLoading = false;
        state.panle_Type = action.payload;
      })
      .addCase(UpdateAdminInformation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateAdminInformation = action.payload;
      })
      .addCase(get_history1.fulfilled, (state, action) => {
        state.isLoading = false;
        state.get_history1 = action.payload;
      })
      .addCase(HistoryByPanelname.fulfilled, (state, action) => {
        state.isLoading = false;
        state.HistoryByPanelname = action.payload;
      })
      .addCase(Addleads.fulfilled, (state, action) => {
        state.isLoading = false;
        state.Addlead = action.payload;
      })
      .addCase(GetAllLeads.fulfilled, (state, action) => {
        state.isLoading = false;
        state.GetAllLeads = action.payload;
      })
      .addCase(UpdateLeadUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.UpdateLeadUser = action.payload;
      })
      .addCase(DeleteLeadsUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.DeleteLeadsUser = action.payload;
      })
      .addCase(GetLeadsHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.GetLeadsHistory = action.payload;
      })
      .addCase(GetPerUserHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.GetPerUserHistory = action.payload;
      })
  },

});

export default AllPanles;
