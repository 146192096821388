import { configureStore } from "@reduxjs/toolkit";

//AUTH SLICE
import AuthSlice from "../Slice/Auth/LoginSlice";
import AllPanles from '../Slice/Panles/PanleSlice'

const store = configureStore({
  reducer: {
    AuthSlice: AuthSlice.reducer, 
    AllPanles:AllPanles.reducer
  },
});

export default store;
