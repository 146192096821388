import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Login from '../Components/Login';
import PanleRoute from './User_Route';

const Main_Route = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const roles = "ADMIN";
    const user_details = JSON.parse(localStorage.getItem("user_details")) || null;

    useEffect(() => {
        if (!user_details || !roles || location.pathname === "/login") {
            navigate("/login");
            return;
        }

        switch (roles) {
            case "ADMIN":
                if (location.pathname === "/login" || location.pathname === "/") {
                    navigate("/panle/dashboard");
                }
                break;
            default:
                navigate("/login");
                break;
        }
    }, [navigate, location.pathname, roles, user_details]);

    return (
        <>
            <Routes>
                <Route path="/panle/*" element={roles === "ADMIN" ? <PanleRoute /> : <Login />} />
                <Route path="/login" element={<Login />} />
            </Routes>
        </>
    );
}

export default Main_Route;
