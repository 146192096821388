import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LOGIN_USER } from "../../../Service/Auth.services";


export const Login = createAsyncThunk("/login", async (data) => {
  try {
    const res = await LOGIN_USER(data);
    return await res;
  } catch (err) {
    return err;
  }
});

 
const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState: {
    isLoading: false,
    isError: false,
    login : [],
  },

  reducers: {},  
  extraReducers: (builder) => {
    builder
      .addCase(Login.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(Login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.login = action.payload;
      })
      .addCase(Login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
       
  },
   
});

export default AuthSlice;
