import React from 'react'
import { Route, Routes } from "react-router-dom";
import Header from '../Components/Header'
import Dashboard from '../Components/Dashboard';
import AllPanles from '../Components/AllPanles'
import EditPanle from '../Components/EditPanle'
import History from '../Components/History'
import AddPanles from '../Components/AddPanle'
import Report from '../Components/Report';
import Leadshistory from '../Components/Leads_History';
import Allleads from '../Components/Allleads';

import Addleads from '../Components/Addleads'
import Updateleads from '../Components/Updateleads';



const PanleRoute = () => {
    return (
        <>
            <Header />
            {/* <Sidebar /> */}
            <div style={{ marginTop: "7rem" }}>
                <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />

                    <Route path="/allpanles" element={<AllPanles />} />
                    <Route path="/addpanles" element={<AddPanles />} />
                    <Route path="/edit" element={<EditPanle />} />
                    <Route path="/history" element={<History />} />
                    <Route path="/updateleads" element={<Updateleads/>} />


                    <Route path="/addlead" element={<Addleads />} />
                    <Route path="/Leads" element={<Allleads />} />
                    <Route path="/leads/history" element={<Leadshistory />} />

                    <Route path="/report" element={<Report />} />
                </Routes>

            </div>

        </>
    )
}

export default PanleRoute