import React, { useEffect, useState, useMemo } from "react";
import { Get_All_Panles } from "../ReduxStore/Slice/Panles/PanleSlice";
import { Get_History } from "../ReduxStore/Slice/Panles/PanleSlice";
import { useDispatch } from "react-redux";
import FullDataTable from "./FulldataTable";
import { fDateTime } from "../Utils/Date_formet";
import { Eye } from "lucide-react";
import ExportToExcel from "../Utils/ExportCSV";

const Report = () => {
  const dispatch = useDispatch();
  const [refresh, setrefresh] = useState(false);
  const [ForGetCSV, setForGetCSV] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [Activeuser, setActiveuser] = useState([]);
  const [openTable, setOpenTable] = useState(null);
  const [aggregateData, setAggregateData] = useState({
    TotalActiveCount: 0,
    inactiveCount: 0,
    addCount: 0,
    deleteCount: 0,
  });




  const fetchData = async () => {
    try {
      const response = await dispatch(Get_History()).unwrap();
      if (response.status) {
        const filteredData = response.data.filter((row) => {
          if (selectedMonth) {
            const rowDate = new Date(row.createdAt);
            const rowMonth = `${rowDate.getFullYear()}-${String(
              rowDate.getMonth() + 1
            ).padStart(2, "0")}`;
            return rowMonth === selectedMonth;
          }
          return true;
        });

        setActiveuser(filteredData);

        const TotalActiveCount = filteredData.filter(
          (row) => row.Message === "Panel Status Update status: On"
        ).length;

        const inactiveCount = filteredData.filter(
          (row) => row.Message === "Panel Status Update status: Off"
        ).length;

        const addCount = filteredData.filter(
          (row) =>
            row.Message === "new Panel is added" ||
            row.Message === "new panle is added"
        ).length;

        const deleteCount = filteredData.filter(
          (row) => row.Message === "Panel is Deleted"
        ).length;

        setAggregateData({
          TotalActiveCount,
          inactiveCount,
          addCount,
          deleteCount,
        });
      } else {
      }
    } catch (err) {
      console.log("Error in fetching History", err);
    }
  };




  useEffect(() => {
    fetchData();
  }, [selectedMonth]);



  const columns2 = useMemo(
    () => [
      {
        accessorKey: "No.",
        header: "No.",
        muiTableHeadCellProps: { sx: { color: "green" } },
        size: 20,
        Cell: ({ cell }) => <strong>{cell.row.index + 1}</strong>,
        headerStyle: { width: "10px" },
      },
      {
        accessorKey: "Panle_name",
        header: "Panel Name",
        size: 200,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
      },
      {
        accessorKey: "Name",
        header: "Name",
        size: 200,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
        headerStyle: { width: "160px" },
      },
      {
        accessorKey: "Message",
        header: "Message",
        size: 300,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
        headerStyle: { width: "300px" },
      },

      {
        accessorKey: "createdAt",
        header: "Time",
        size: 200,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => (
          <strong>
            {renderedCellValue ? fDateTime(renderedCellValue) : "-"}
          </strong>
        ),
      },
    ],
    []
  );




  //print table data  concept
  const forCSVdata = () => {
    let csvArr = [];
    if (Activeuser.length > 0) {
      Activeuser.map((item) => {
        return csvArr.push({
          Name: item.Name,
          Panle_name: item.Panle_name,
          Message: item.Message,
          createdAt: item.createdAt,
        });
      });

      setForGetCSV(csvArr);
    }
  };



  
  useEffect(() => {
    forCSVdata();
  }, [Activeuser]);

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const getFilteredCSVData = (status) => {
    return ForGetCSV.filter((row) => {
      if (selectedMonth) {
        const rowDate = new Date(row.createdAt);
        const rowMonth = `${rowDate.getFullYear()}-${String(
          rowDate.getMonth() + 1
        ).padStart(2, "0")}`;
        return row.Message === status && rowMonth === selectedMonth;
      }
      return row.Message === status;
    });
  };




  const getFilteredCSVData1 = () => {
    return ForGetCSV.filter((row) => {
      if (selectedMonth) {
        const rowDate = new Date(row.createdAt);
        const rowMonth = `${rowDate.getFullYear()}-${String(
          rowDate.getMonth() + 1
        ).padStart(2, "0")}`;
        return (
          (row.Message === "new Panel is added" ||
            row.Message === "new panle is added") &&
          rowMonth === selectedMonth
        );
      }
      return (
        row.Message === "new Panel is added" ||
        row.Message === "new panle is added"
      );
    });
  };

  return (
    <>
      <div className="pagetitle" style={{ marginTop: "50px" }}>
        <h1>All Panel Status</h1>
      </div>
      <div>
        <input
          type="month"
          className="form-control"
          onChange={handleMonthChange}
        />
      </div>

      <section className="section dashboard" style={{ marginTop: "30px" }}>
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-xxl-3 col-md-4">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <h5 className="card-title">Total ActiveCount</h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-people"></i>
                      </div>
                      <div className="ps-3">
                        <h6>{aggregateData.TotalActiveCount}</h6>
                      </div>
                      <Eye
                        style={{ marginLeft: "20px" }}
                        onClick={() =>
                          setOpenTable(openTable === "active" ? null : "active")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-3 col-md-4">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <h5 className="card-title">Total InActive</h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-people"></i>
                      </div>
                      <div className="ps-3">
                        <h6>{aggregateData.inactiveCount}</h6>
                      </div>
                      <Eye
                        style={{ marginLeft: "20px" }}
                        onClick={() =>
                          setOpenTable(
                            openTable === "inactive" ? null : "inactive"
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-3 col-md-4">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <h5 className="card-title">Total Add</h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-people"></i>
                      </div>
                      <div className="ps-3">
                        <h6>{aggregateData.addCount}</h6>
                      </div>
                      <Eye
                        style={{ marginLeft: "20px" }}
                        onClick={() =>
                          setOpenTable(openTable === "add" ? null : "add")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-3 col-md-4">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <h5 className="card-title">Total Deleted</h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-people"></i>
                      </div>
                      <div className="ps-3">
                        <h6>{aggregateData.deleteCount}</h6>
                      </div>
                      <Eye
                        style={{ marginLeft: "20px" }}
                        onClick={() =>
                          setOpenTable(openTable === "delete" ? null : "delete")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div data-aos="fade-left">
        {openTable === "active" && (
          <div className="card mt-5">
            <div
              className="card-header"
              style={{ backgroundColor: "#b2c6ed", color: "black" }}
            >
              <div>
                <div
                  className="dropdown dropdown-action"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Download"
                >
                  Total ActiveCount
                  <ExportToExcel
                    className="btn btn-primary"
                    apiData={getFilteredCSVData(
                      "Panel Status Update status: On"
                    )}
                    fileName={"Total ActiveCount"}
                  />
                </div>
              </div>
            </div>
            <div className="card-body">
              <FullDataTable
                columns={columns2}
                data={Activeuser.filter(
                  (row) => row.Message === "Panel Status Update status: On"
                )}
              />
            </div>
          </div>
        )}
      </div>

      <div data-aos="fade-left">
        {openTable === "inactive" && (
          <div className="card mt-5">
            <div
              className="card-header"
              style={{ backgroundColor: "#b2c6ed", color: "black" }}
            >
              <div>
                <div
                  className="dropdown dropdown-action"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Download"
                >
                  Total InActive
                  <ExportToExcel
                    className="btn btn-primary"
                    apiData={getFilteredCSVData(
                      "Panel Status Update status: Off"
                    )}
                    fileName={"Total InActive"}
                  />
                </div>
              </div>
            </div>
            <div className="card-body">
              <FullDataTable
                columns={columns2}
                data={Activeuser.filter(
                  (row) => row.Message === "Panel Status Update status: Off"
                )}
              />
            </div>
          </div>
        )}
      </div>

      <div data-aos="fade-left">
        {openTable === "add" && (
          <div className="card mt-5">
            <div
              className="card-header"
              style={{ backgroundColor: "#b2c6ed", color: "black" }}
            >
              <div>
                <div
                  className="dropdown dropdown-action"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Download"
                >
                  Total Add
                  <ExportToExcel
                    className="btn btn-primary"
                    apiData={getFilteredCSVData1("new Panel is added")}
                    fileName={"Total Add"}
                  />
                </div>
              </div>
            </div>
            <div className="card-body">
              <FullDataTable
                columns={columns2}
                data={Activeuser.filter(
                  (row) =>
                    row.Message === "new Panel is added" ||
                    row.Message === "new panle is added"
                )}
              />
            </div>
          </div>
        )}
      </div>

      <div data-aos="fade-left">
        {openTable === "delete" && (
          <div className="card mt-5">
            <div
              className="card-header"
              style={{ backgroundColor: "#b2c6ed", color: "black" }}
            >
              <div>
                <div
                  className="dropdown dropdown-action"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Download"
                >
                  deleteCount
                  <ExportToExcel
                    className="btn btn-primary"
                    apiData={getFilteredCSVData("Panel is Deleted")}
                    fileName={"Total deleted"}
                  />
                </div>
              </div>
            </div>
            <div className="card-body">
              <FullDataTable
                columns={columns2}
                data={Activeuser.filter(
                  (row) => row.Message === "Panel is Deleted"
                )}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Report;
