import React, { useEffect, useState, useMemo } from "react";
import { Get_All_Panles } from "../ReduxStore/Slice/Panles/PanleSlice";
import { useDispatch } from "react-redux";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [refresh, setrefresh] = useState(false);
  const [getAllPanles, setAllPanles] = useState({
    loading: true,
    data: [],
    countActivePanles: 0,
  });

  //get all data

  const data = async () => {
    await dispatch(Get_All_Panles())
      .unwrap()
      .then((response) => {
        if (response.status) {
          setAllPanles({
            loading: false,
            data: response.data,
            countActivePanles: response.statusCount,
          });
        } else {
          setAllPanles({
            loading: false,
            data: response.data,
            countActivePanles: 0,
          });
        }
      })
      .catch((err) => {
        console.log("Error in fetching the all panels", err);
      });
  };

  useEffect(() => {
    data();
  }, [refresh]);

  return (
    <div style={{margin:"3rem"}}>
      <div className="pagetitle">
        <h1>Dashboard</h1>
      </div>
      <section className="section dashboard">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-xxl-3 col-md-4">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <h5 className="card-title">Total Panels</h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-people"></i>
                      </div>
                      <div className="ps-3">
                        <h6>{getAllPanles.data.length}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-3 col-md-4">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <h5 className="card-title">Total Active Panels</h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-people"></i>
                      </div>
                      <div className="ps-3">
                        <h6>{getAllPanles.countActivePanles}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-3 col-md-4">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <h5 className="card-title">Total Inactive Panels</h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-people"></i>
                      </div>
                      <div className="ps-3">
                        <h6>
                          {getAllPanles.data.length -
                            getAllPanles.countActivePanles}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
