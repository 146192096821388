
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AddForm from "./FormikForm";
import {Panle_Type } from "../ReduxStore/Slice/Panles/PanleSlice";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { UpdateLeadUser } from "../ReduxStore/Slice/Panles/PanleSlice";

const Updateleads = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const location = useLocation()
  const RowData = location.state
  
  const [getPanleType, setPanleType] = useState({
    loading: true,
    data: []
  })



  const userName = JSON.parse(localStorage.getItem("user_details")).FullName;



  const formik = useFormik({
    initialValues: {
      Panel_Name: "",
      Panle_Type: "",
      Owner_Email: '',
      Owner_name: '',
      Owner_Phone: '',
      status:""
     
      
    },
    validate: (values) => {
      let errors = {};
    //   if (!values.Domain_name && formik.touched.Domain_name) {
    //     errors.Domain_name = "Enter Domain Name"
    //   }

      // if (!values.Port && formik.touched.Port) {
      //   errors.Port = "Enter Port Number"
      // }

    //   if (!values.Database_Ip && formik.touched.Database_Ip) {
    //     errors.Database_Ip = "Enter Database Ip"
    //   }

      if (!values.Panel_Name && formik.touched.Panel_Name) {
        errors.Panel_Name = "Enter Panel Name"
      }

      // if (!values.Owner_name.trim() && formik.touched.Owner_name) {
      //   errors.Owner_name = "Enter Owner Name"
      // }

      // if (!values.Owner_Phone.trim() && formik.touched.Owner_Phone) {
      //   errors.Owner_Phone = "Enter Owner Phone no"
      // }
     
      if (!values.Panle_Type && formik.touched.Panle_Type) {
        errors.Panle_Type = "Select any one Panel type"
      }
      
    //   if (!values.RDP && formik.touched.RDP) {
    //     errors.RDP = "RDP is Require"
    //   }
       

      return errors;
    },
    onSubmit: async (values) => {
      const req = {
        id: RowData._id,
        Panel_Name: values.Panel_Name,
        Panle_Type: values.Panle_Type,
        Owner_Email: values.Owner_Email,
        Owner_name: values.Owner_name,
        Owner_Phone: values.Owner_Phone,
        userName: userName,
        status: values.status
         
      };
      await dispatch(UpdateLeadUser(req))
        .unwrap()
        .then(async (response) => {
          if (response.status) {
            Swal.fire({
              title: "Updated",
              text: "Admin Information Successfull !",
              icon: "success",
              timer: 1500,
              timerProgressBar: true,
            });
            setTimeout(() => {
              navigate("/panle/Leads");
            }, 1500);
          } else {
            Swal.fire({
              title: "error",
              text: "Unable to updated !",
              icon: "error",
              timer: 1500,
              timerProgressBar: true,
            });

          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
  });

  useEffect(() => {
    formik.setFieldValue("Panel_Name", RowData.Panel_Name)
    formik.setFieldValue("Panle_Type", RowData.Panle_Type)
    formik.setFieldValue("Owner_Email", RowData.Owner_Email)
    formik.setFieldValue("Owner_name", RowData.Owner_name)
    formik.setFieldValue("Owner_Phone", RowData.Owner_Phone)
    formik.setFieldValue("status", RowData.status)
    formik.setFieldValue("Refer", RowData.Refer)
   
  }, [])

  const fields = [
    {
      name: "Panel_Name",
      label: "Panel Name",
      type: "text",
      label_size: 12,
      col_size: 6,
      disable: false,
    },
    {
      name: "Panle_Type",
      label: "Panel Type",
      type: "select",
      options: getPanleType.data && getPanleType.data.map((item) => ({
        label: item.Panle_Type,
        value: item.Panle_Type,
      })),
      label_size: 12,
      col_size: 6,
      disable: false,
    },
    {
      name: "Owner_name",
      label: "Owner Name",
      type: "text",
      label_size: 12,
      col_size: 6,
      disable: false,
    },
    {
      name: "Owner_Phone",
      label: "Owner Phone",
      type: "text3",
      label_size: 12,
      col_size: 6,
      disable: false,
    },
    {
      name: "Owner_Email",
      label: "Owner Email",
      type: "text",
      label_size: 12,
      col_size: 6,
      disable: false,
    },
   
    {
      name: "status",
      label: "Status",
      type: "select",
      options: [
        { label: "Pending", value: "Pending" },
        { label: "Complete", value: "Complete" },
        { label: "Reject", value: "Reject" },
      ],
      label_size: 12,
      col_size: 6,
      disable: false,
      onChange: (e) => formik.setFieldValue('status', e.target.value),
    },
    {
      name: "Refer",
      label: "Refer",
      type: "text",
      label_size: 12,
      col_size: 6,
      disable: true,
    },
  ];
  




  const getPanle_Type = async () => {
    try {
      await dispatch(Panle_Type()).unwrap()
        .then((response) => {
          if (response.status) {
            setPanleType({
              loading: false,
              data: response.data
            })

          }
          else {
            setPanleType({
              loading: false,
              data: []
            })
          }
        })

    }
    catch (error) {
      console.log("Error in finding the Panel tpye", error)
    }
  }


  useEffect(() => {
    getPanle_Type()
  }, [])




  return (

    <>
      <div className="m-5">
        <AddForm
          fields={fields.filter(
            (field) => !field.showWhen || field.showWhen(formik.values)
          )}
          page_title="Edit Panel "
          btn_name="Edit Panel "
          btn_name1="Cancel"
          formik={formik}
          btn_name1_route={"/panle/Leads"}
        />
      </div>
    </>
  );
};
export default Updateleads;
