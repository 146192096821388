import React, { useState } from 'react'
import logo from '../assest/img/pnp.png'
import user from '../assest/img/user.png'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { Home, Users, History, ClipboardMinus } from 'lucide-react';


const Header = () => {
    const [showDrop, setShowdropdown] = useState(false)
    const navigate = useNavigate()
    const userName = JSON.parse(localStorage.getItem("user_details")).FullName;

    const handleLogOut = () => {

        localStorage.removeItem('Role')
        localStorage.removeItem('user_details')
        Swal.fire({
            title: "Log out !",
            text: "User Logout successfully...",
            icon: "success",
            timer: 1500,
            timerProgressBar: true,
        })
        setTimeout(() => {
            navigate('/login')
        }, 1500)

    }
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const handleToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
        document.body.classList.toggle('toggle-sidebar', !isSidebarOpen);
    };

    return (
        <div>
            <header id="header" className="header fixed-top d-flex align-items-center">

                <div className="d-flex align-items-center justify-content-between">
                    <a href="index.html" className="logo d-flex align-items-center">
                        <img src={logo} alt="pnp logo" width={"200px"} />
                    </a>
                </div>

                <nav className="header-nav d-flex ">
                    <ul className="d-flex align-items-center">

                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/panle/dashboard">
                                <Home className="bi" />
                                <span className='mx-2'>Dashboard</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/panle/allpanles">
                                <Users className="bi" />
                                <span className='mx-2'>All Panels</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/panle/history">
                                <History className="bi" />
                                <span className='mx-2'>Panle History</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/panle/Leads">
                                <Users className="bi" />
                                <span className='mx-2'>All Leads</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/panle/leads/history">
                                <History className="bi" />
                                <span className='mx-2'>Leads History</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to="/panle/report">
                                <ClipboardMinus className="bi" />
                                <span className='mx-2'>Report</span>
                            </Link>
                        </li>
                        {/* Right Corner par  */}
                        <li className="nav-item dropdown pe-3" style={{ marginLeft: "350px" }}>
                            <a
                                className="nav-link nav-profile d-flex align-items-center pe-0" onClick={() => setShowdropdown(!showDrop)}
                                href="#"
                                data-bs-toggle="dropdown"
                            >
                                <img
                                    src={user}
                                    alt="Profile"
                                    className="rounded-circle"
                                />
                                <span className="d-none d-md-block dropdown-toggle ps-2">
                                    {userName}
                                </span>
                            </a>
                            {/* End Profile Iamge Icon */}
                            <ul className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow profile ${showDrop ? "show " : ""}`}

                                style={
                                    showDrop
                                        ? {
                                            position: "absolute",
                                            inset: "0px 0px auto auto",
                                            margin: 0,
                                            transform: "translate(-16px, 38px)",
                                        }
                                        : {}
                                } >

                                <li>
                                    <p className="dropdown-item d-flex align-items-center" onClick={handleLogOut}>
                                        <i className="bi bi-box-arrow-right" />
                                        <span>Sign Out</span>
                                    </p>
                                </li>
                            </ul>
                        </li>

                    </ul>
                </nav>

            </header>


        </div>
    )
}

export default Header
