import React from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable
} from 'material-react-table';

const FulldataTable = ({ data, columns }) => {

    // Add muiTableBodyCellProps to center the content
    const styledColumns = columns.map((column) => ({
        ...column,
        muiTableBodyCellProps: {
            sx: {
                textAlign: 'start'
            }
        },
        muiTableHeadCellProps: {
            sx: {
                textAlign: 'start'
            }
        }
    }));

    return (
        <div style={{ maxHeight: "600px", overflow: "auto" }}>
            <MaterialReactTable
                columns={styledColumns}
                data={data}
            />
        </div>
    );
};

export default FulldataTable;
