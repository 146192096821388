import React, { useState } from 'react';
import logo from '../assest/img/pnp.png';
import { useDispatch } from 'react-redux';
import { Login } from '../ReduxStore/Slice/Auth/LoginSlice';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const LoginUser = () => {
  const dispatch = useDispatch();
  const [emailId, setEmailId] = useState('');
  const [pass, setPass] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!validateEmail(emailId)) {
      setEmailError('Invalid email address');
      return;
    }

    try {
      const req = { Email: emailId, Password: pass };
      await dispatch(Login(req)).unwrap().then((response) => {
        if (response.status) {
          localStorage.setItem('user_details', JSON.stringify(response.data));
          localStorage.setItem('Role', JSON.stringify('USER'));
          Swal.fire({
            title: "Login !",
            text: "User Login successfully...",
            icon: "success",
            timer: 1500,
            timerProgressBar: true,

          });
          setTimeout(()=>{
            navigate('/panle/dashboard');
          },1500)
        }
        else{
          Swal.fire({
            title: "Error !",
            text: "Invalid credentials...",
            icon: "error",
            timer: 1500,
            timerProgressBar: true,

          });
        }
      });
    } catch (error) {
      console.log('Error in login', error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container">
      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <div className="d-flex justify-content-center py-4">
                <img src={logo} alt=" pnp logo" style={{ height: '80px' }} />
              </div>
              {/* End Logo */}
              <div className="card mb-3">
                <div className="card-body">
                  <div className="pt-4 pb-2">
                    <h5 className="card-title text-center pb-0 fs-4">
                      Login to Your Account
                    </h5>

                  </div>
                  <div className="row g-3 needs-validation">
                    <div className="col-12">
                      <label htmlFor="yourUsername" className="form-label">
                        Email
                      </label>
                      <div className="input-group has-validation">
                        <input
                          type="text"
                          name="username"
                          className="form-control"
                          id="yourUsername"
                          required=""
                          placeholder="Enter your email"
                          onChange={(e) => {
                            setEmailId(e.target.value);
                            setEmailError('');
                          }}
                          value={emailId}
                        />
                      </div>
                      <div>
                        {emailError && <div className="text-danger">{emailError}</div>}
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="yourPassword" className="form-label">
                        Password
                      </label>
                      <div className="input-group">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          className="form-control"
                          id="yourPassword"
                          required=""
                          placeholder="Enter your Password"
                          onChange={(e) => setPass(e.target.value)}
                          value={pass}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? 'Hide' : 'Show'}
                        </button>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 mt-5"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Card */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginUser;
