import React, { useEffect, useState, useMemo } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { HistoryByPanelname } from "../ReduxStore/Slice/Panles/PanleSlice";
import ExportToExcel from "../Utils/ExportCSV";

import FullDataTable from "./FulldataTable";
import {
  Get_All_Panles,
  DeletePanle,
  UPDATE_USER_ACTIVE_STATUS,
  UpdateAdminInformation,
} from "../ReduxStore/Slice/Panles/PanleSlice";
import { useDispatch } from "react-redux";
import { Pencil, Trash2, RefreshCcw, Eye, History } from "lucide-react";
import { fDateTime, fDate } from "../Utils/Date_formet";
import Swal from "sweetalert2";
import Loader from "../Utils/Loader";




const AllClients = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const [refresh, setrefresh] = useState(false);
  const [inputSearch, setInputSerch] = useState("");
  const [ShowModal, setShowModal] = useState(false);
  const [panelType, setPanelType] = useState(null);
  const [open, setOpen] = useState(false);
  const [history, setHistory] = useState([]);
  const [historysearch, setHistorysearch] = useState("");
  const [selectedDomainName, setSelectedDomainName] = useState("");
  const [date, setDate] = useState("");
  const [ForGetCSV, setForGetCSV] = useState([]);
 



  const [getAllPanles, setAllPanles] = useState({
    loading: true,
    data: [],
  });

  const [admin_info, setAdmin_info] = useState({
    id: "",
    Maintenance_Charges: "",
    Licence_ID_Charges: "",
    Total_Pending: "",
    Current_Pending: "",
    Received: "",
    LicenceType: "",
  });



  // getting all client data

  const data = async () => {
    await dispatch(Get_All_Panles())
      .unwrap()
      .then((response) => {
        if (response.status) {
          const filterData =
            response.data &&
            response.data.filter((item) => {


              const filter2Match =
                panelType == null || item.Panle_Type.includes(panelType);

              const inputSearchMatch =
                inputSearch == "" ||
                item.Owner_Phone.toLowerCase().includes(
                  inputSearch.toLowerCase()
                ) ||
                item.Domain_name.toLowerCase().includes(
                  inputSearch.toLowerCase()
                ) ||
                item.Owner_Email.toLowerCase().includes(
                  inputSearch.toLowerCase()
                ) ||
                item.Owner_name.toLowerCase().includes(
                  inputSearch.toLowerCase()
                ) ||
                item.Panel_Name.toLowerCase().includes(
                  inputSearch.toLowerCase()
                );

              return filter2Match && inputSearchMatch;

            });


          setAllPanles({
            loading: false,
            data:
              inputSearch || panelType !== null ? filterData : response.data,
          });

        } else {
          setAllPanles({
            loading: false,
            data: [],
          });
        }
      })
      .catch((err) => {
        console.log("Error in fatching the all panles", err);
      });
  };

  useEffect(() => {
    data();
  }, [refresh, inputSearch, panelType]);

  const handleEdit = (row) => {
    navigate("/panle/edit", { state: row });
  };



  // handler for delete
  const handleDelete = async (id) => {
    const data = { id: id, userName: user_details.FullName };
    await dispatch(DeletePanle(data))
      .unwrap()
      .then((response) => {
        if (response.status) {
          Swal.fire({
            title: "Deleted",
            text: "Panel  deleted successfull...",
            icon: "success",
            timer: 1500,
            timerProgressBar: true,
          });
          setrefresh(!refresh);
        }
      })
      .catch((err) => {
        console.log("Error in delete the panle", err);
      });
  };


  
  // ACTIVE USER TO API
  const activeUser = async (e, data, type) => {
    const currentStatus =
      type === 4 ? e.target.value : e.target.checked ? "1" : "0";
    const statusText = currentStatus === "1" ? "on" : "off";




    // Show confirmation dialog with SweetAlert2
    const result = await Swal.fire({
      title: `Do you want to change the status to ${statusText}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (result.isConfirmed) {
      let req = {
        id: data._id,
        status: currentStatus,
        type: type,
        userName: user_details.FullName,
      };

      try {
        const response = await dispatch(
          UPDATE_USER_ACTIVE_STATUS(req)
        ).unwrap();

        if (response.status) {
          Swal.fire({
            title: "Success",
            text: "Status is Updated!",
            icon: "success",
            timer: 1500,
            timerProgressBar: true,
          });
          setrefresh((prev) => !prev);
        } else {
          Swal.fire({
            title: "Error",
            text: "Failed to update status.",
            icon: "error",
            timer: 1500,
            timerProgressBar: true,
          });
        }
      } catch (error) {
        console.error("Error updating user status:", error);
        Swal.fire({
          title: "Error",
          text: "An error occurred while updating the status.",
          icon: "error",
          timer: 1500,
          timerProgressBar: true,
        });
      }
    } else {
      setrefresh((prev) => !prev);
    }
  };





  const columns1 = useMemo(
    () => [
      {
        accessorKey: "#",
        header: "#",
        muiTableHeadCellProps: { sx: { color: "green" } },
        size: 10,
        Cell: ({ cell, index }) => <strong>{cell.row.index + 1}</strong>,
      },

      {
        accessorKey: "Domain_name",
        header: "Domain Name",
        size: 200,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
      },

      {
        accessorKey: "Panle_Type",
        header: "Panel  Type",
        size: 100,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
      },
      {
        accessorKey: "RDP",
        header: "RDP",
        size: 100,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => (
          <strong>{renderedCellValue ? renderedCellValue : "-"}</strong>
        ),
      },
      {
        accessorKey: "Owner_name",
        header: "Owner Name",
        size: 100,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
      },

      {
        accessorKey: "Owner_Phone",
        header: "Owner Phone",
        size: 100,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
      },

      {
        accessorKey: "agreement",
        header: "Agreement",
        size: 100,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ cell }) => (
          <select
            value={cell.row.original.agreement}
            onChange={(e) => {
              const value = e.target.value;
              activeUser(e, cell.row.original, 4);
            }}
          >
            <option value="2">Pending</option>
            <option value="0">Incomplete</option>
            <option value="1">Completed</option>
          </select>
        ),
      },

      {
        accessorKey: "E_Sign",
        header: "E Sign",
        size: 100,
        muiTableHeadCellProps: { sx: { color: "green" } },

        Cell: ({ cell, index }) => (
          <>
            <label className="switch">
              <input
                type="checkbox"
                checked={cell.row.original.E_Sign === "1" ? true : false}
                onChange={(e) => {
                  activeUser(e, cell.row.original, 1);
                }}
              />
              <span className="slider round"></span>
            </label>
          </>
        ),
      },

      {
        accessorKey: "Digital_Marketing",
        header: "SEO",
        size: 100,
        muiTableHeadCellProps: { sx: { color: "green" } },

        Cell: ({ cell, index }) => (
          <>
            <label className="switch">
              <input
                type="checkbox"
                checked={
                  cell.row.original.Digital_Marketing === "1" ? true : false
                }
                onChange={(e) => {
                  activeUser(e, cell.row.original, 2);
                }}
              />
              <span className="slider round"></span>
            </label>
          </>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 100,
        muiTableHeadCellProps: { sx: { color: "green" } },

        Cell: ({ cell, index }) => (
          <>
            <label className="switch">
              <input
                type="checkbox"
                checked={cell.row.original.status === "1" ? true : false}
                onChange={(e) => {
                  activeUser(e, cell.row.original, 3);
                }}
              />
              <span className="slider round"></span>
            </label>
          </>
        ),
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 100,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ cell, index }) => (
          <>
            <div
              style={{ width: "120px" }}
              className="d-flex justify-content-start gap-2"
            >
              <Pencil
                onClick={() => {
                  handleEdit(cell.row.original);
                }}
              />
              <Trash2 onClick={() => handleDelete(cell.row.original._id)} />
              <Eye
                onClick={() => {
                  setShowModal(true);
                  setAdmin_info({
                    id: cell.row.original._id,
                    Maintenance_Charges:
                      cell.row.original.adminInfos[0] &&
                      cell.row.original.adminInfos[0].Maintenance_Charges,
                    Licence_ID_Charges:
                      cell.row.original.adminInfos[0] &&
                      cell.row.original.adminInfos[0].Licence_ID_Charges,
                    Total_Pending:
                      cell.row.original.adminInfos[0] &&
                      cell.row.original.adminInfos[0].Total_Pending,
                    Current_Pending:
                      cell.row.original.adminInfos[0] &&
                      cell.row.original.adminInfos[0].Current_Pending,
                    Received:
                      cell.row.original.adminInfos[0] &&
                      cell.row.original.adminInfos[0].Received,
                    LicenceType:
                      cell.row.original.adminInfos[0] &&
                      cell.row.original.adminInfos[0].LicenceType,
                  });
                }}
              />
            </div>
          </>
        ),
      },
      {
        accessorKey: "Maintenance_Charges",
        header: "Maintenance Charges",
        size: 100,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ cell }) => {
          return (
            <strong>
              {cell.row.original.adminInfos[0]?.Maintenance_Charges || "-"}
            </strong>
          );
        },
      },
      {
        accessorKey: "Licence_ID_Charges",
        header: "Licence_ID_Charges",
        size: 100,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ cell }) => {
          return (
            <strong>
              {cell.row.original.adminInfos[0]?.Licence_ID_Charges || "-"}
            </strong>
          );
        },
      },
      {
        accessorKey: "Total_Pending",
        header: "Total_Pending",
        size: 100,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ cell }) => {
          return (
            <strong>
              {cell.row.original.adminInfos[0]?.Total_Pending || "-"}
            </strong>
          );
        },
      },
      {
        accessorKey: "Current_Pending",
        header: "Current_Pending",
        size: 100,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ cell }) => {
          return (
            <strong>
              {cell.row.original.adminInfos[0]?.Current_Pending || "-"}
            </strong>
          );
        },
      },
      {
        accessorKey: "Received",
        header: "Received",
        size: 100,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ cell }) => {
          return (
            <strong>{cell.row.original.adminInfos[0]?.Received || "-"}</strong>
          );
        },
      },
      {
        accessorKey: "LicenceType",
        header: "LicenceType",
        size: 100,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ cell }) => {
          return (
            <strong>
              {cell.row.original.adminInfos[0]?.LicenceType || "-"}
            </strong>
          );
        },
      },

      {
        accessorKey: "History",
        header: "History",
        size: 100,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ cell, index }) => (
          <>
            <div
              style={{ width: "120px" }}
              className="d-flex justify-content-start gap-2"
            >
              <History
                onClick={() => {
                  setOpen(true);
                  setSelectedDomainName(cell.row.original.Domain_name);
                }}
              />
            </div>
          </>
        ),
      },
    ],
    [open]
  );



  const RefreshHandle = () => {
    setInputSerch("");
    setrefresh(!refresh);
    setPanelType(null);
    setHistorysearch("");
    setDate("");
  };




  const handleChange = (event) => {
    const { name, value } = event.target;
    setAdmin_info((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };





  const handleUpdate = async () => {
    const data = { admin_info: admin_info, userName:user_details.FullName };
   
    await dispatch(UpdateAdminInformation(data))
      .unwrap()
      .then((response) => {
        if (response.status) {
          Swal.fire({
            title: "Updated",
            text: "Admin Information Successfull !",
            icon: "success",
            timer: 1500,
            timerProgressBar: true,
          });
          setShowModal(false);
          setrefresh(!refresh);
        } else {
          Swal.fire({
            title: "Error",
            text: "Admin Updation Error !",
            icon: "error",
            timer: 1500,
            timerProgressBar: true,
          });
          setShowModal(false);
          setrefresh(!refresh);
        }
      })
      .catch((error) => {
        console.log("Internal server Error", error);
      });
  };





  // history data

  const columns = useMemo(
    () => [
      {
        accessorKey: "No.",
        header: "No.",
        muiTableHeadCellProps: { sx: { color: "green" } },
        size: 20,
        Cell: ({ cell, index }) => <strong>{cell.row.index + 1}</strong>,
        headerStyle: { width: "10px" },
      },
      {
        accessorKey: "Panle_name",
        header: "Panle_name",
        size: 200,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
      },
      {
        accessorKey: "Name",
        header: "Name",
        size: 200,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
        headerStyle: { width: "160px" },
      },
      {
        accessorKey: "Message",
        header: "Message",
        size: 300,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
        headerStyle: { width: "300px" },
      },
      {
        accessorKey: "createdAt",
        header: "Time",
        size: 200,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => (
          <strong>{renderedCellValue ? fDate(renderedCellValue) : "-"}</strong>
        ),
      },
    ],
    [history, historysearch, date]
  );



  // fetching by panel name

  const fetchData = async (Domain_name, historysearch, date) => {
    try {
      const data = { Panle_name: Domain_name };
      const response = await dispatch(HistoryByPanelname(data)).unwrap();
      if (response.status) {
        const filterData =
          response.data &&
          response.data.filter((item) => {
            const inputSearchMatch =
              historysearch === "" ||
              item.Name.toLowerCase().includes(historysearch.toLowerCase()) ||
              item.Message.toLowerCase().includes(
                historysearch.toLowerCase()
              ) ||
              item.Panle_name.toLowerCase().includes(
                historysearch.toLowerCase()
              );

            const dateMatch =
              date === "" || (item.createdAt && item.createdAt.includes(date));

            return inputSearchMatch && dateMatch;
          });

        setHistory(historysearch || date ? filterData : response.data);
      }
    } catch (err) {
      console.log("error", err);
    }
  };





  useEffect(() => {
    if (open) {
      fetchData(selectedDomainName, historysearch, date);
    } else {
      setHistorysearch("");
      setDate("");
    }
  }, [open, selectedDomainName, historysearch, date]);





  
  const forCSVdata = () => {
    let csvArr = [];
    if (history.length > 0) {
      history.map((item) => {
        return csvArr.push({
          Name: item.Name,
          Panle_name: item.Panle_name,
          Message: item.Message,
          createdAt: item.createdAt,
        });
      });

      setForGetCSV(csvArr);
    }
  };

  useEffect(() => {
    forCSVdata();
  }, [history]);



  
  return (
    <>
      <div className="content container-fluid" data-aos="fade-left">
        <div className="card mt-5">
          <div className="card-header" style={{ backgroundColor: "#b2c6ed" }}>
            <div className="row align-items-center">
              <div className="col">
                <h5 className="card-title mb-0">
                  <i className="pe-2 fa-solid fa-users"></i>
                  All Panel
                </h5>
              </div>
              <div className="col-auto">
                <div className="list-btn">
                  <ul className="filter-list mb-0 d-flex gap-3">
                    <div className="mt-2">
                      <p
                        className="mb-0 btn-filters"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title="Refresh"
                        onClick={RefreshHandle}
                      >
                        <span>
                          <RefreshCcw />
                        </span>
                      </p>
                    </div>
                    <div className="serach-li">
                      <div className="input-group input-block">
                        <input
                          type="text"
                          className="form-control rounded"
                          placeholder="Search..."
                          aria-label="Search"
                          aria-describedby="search-addon"
                          onChange={(e) => setInputSerch(e.target.value)}
                          value={inputSearch}
                        />
                      </div>
                    </div>
                    <div className="serach-li col-lg-4">
                      <div className="input-group input-block">
                        <select
                          class="default-select wide form-control"
                          aria-label="Default select example"
                          id="select"
                          onChange={(e) => setPanelType(e.target.value)}
                          value={panelType}
                        >
                          <option value="null">Select Panel type</option>
                          <option value="Old panel">Old panel</option>
                          <option value="New Panel">New Panel </option>
                          <option value="Connect Box">Connect Box</option>
                          <option value="Offline Trading">
                            Offline Trading
                          </option>
                          <option value="Scalping">Scalping</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <Link to={"/panle/addpanles"} className="btn btn-primary">
                        <i
                          className="fa fa-plus-circle me-2"
                          aria-hidden="true"
                        />
                        Add Panel
                      </Link>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body">
            {getAllPanles.loading ? (
              <Loader />
            ) : (
              <FullDataTable columns={columns1} data={getAllPanles.data} />
            )}
          </div>
        </div>
      </div>

      {ShowModal && (
        <div
          className="modal custom-modal d-flex"
          id="add_vendor"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-md custom-modal-width">
            <div className="modal-content" style={{ width: "60rem" }}>
              <div className="modal-header border pb-0">
                <div className="form-header modal-header-title text-start mb-0">
                  <h4 className="mb-0">Admin Information</h4>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <table className="table table-responsive-sm table-bordered-0">
                  <tbody className="">
                    <tr>
                      <td className="border">Maintenance Charges</td>
                      <td className="border">
                        <input
                          type="number"
                          name="Maintenance_Charges"
                          placeholder="Maintenance Charges"
                          onChange={handleChange}
                          value={admin_info.Maintenance_Charges}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border">Licence ID Charges</td>
                      <td className="border">
                        <input
                          type="number"
                          name="Licence_ID_Charges"
                          placeholder="Enter Licence ID"
                          onChange={handleChange}
                          value={admin_info.Licence_ID_Charges}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border">Total Pending</td>
                      <td className="border">
                        <input
                          type="number"
                          name="Total_Pending"
                          placeholder="Enter Total Pending"
                          onChange={handleChange}
                          value={admin_info.Total_Pending}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border">Current Pending</td>
                      <td className="border">
                        <input
                          type="number"
                          placeholder="Enter Current Pending"
                          name="Current_Pending"
                          onChange={handleChange}
                          value={admin_info.Current_Pending}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border">Received</td>
                      <td className="border">
                        <input
                          type="number"
                          name="Received"
                          placeholder="Enter Received Amount"
                          onChange={handleChange}
                          value={admin_info.Received}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="border">Licence Type</td>
                      <td className="border">
                        {/* <input
                          type="text"
                          name="LicenceType"
                          placeholder="Enter License Type"
                          onChange={handleChange}
                          value={admin_info.LicenceType}
                        /> */}
                        <select
                          className="default-select wide form-control"
                          aria-label="Default select example"
                          id="select"
                          name="LicenceType" 
                          onChange={handleChange}
                          value={admin_info.LicenceType}
                          style={{width:"190px"}}
                        >
                          <option value="null">SelectLicence Type</option>
                          <option value="Full">Full</option>
                          <option value="Half">Half</option>
                          <option value="Without Pay">Without Pay</option>
                          <option value="Puneet Sir">Puneet Sir</option> 
                          <option value="Burhan Sir">Burhan Sir</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="d-flex justify-content-end mx-3">
                <button
                  className="btn btn-primary mt-2 mb-3  w-25"
                  onClick={handleUpdate}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {open && (
        <div
          className="modal custom-modal d-flex"
          id="add_vendor"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md custom-modal-width"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="modal-content" style={{ width: "70rem" }}>
              <div className="modal-header border pb-0">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setOpen(!open)}
                ></button>
              </div>
              <div className="content container-fluid" data-aos="fade-left">
                <div className="card mt-1">
                  <div
                    className="card-header"
                    style={{ backgroundColor: "#b2c6ed" }}
                  >
                    <div className="row align-items-center">
                      <div className="col">
                        <h5 className="card-title mb-0">
                          <i className="pe-2 fa-solid fa-users"></i>
                          History
                        </h5>
                      </div>
                      <div className="col-auto">
                        <div className="list-btn">
                          <ul className="filter-list mb-0 d-flex gap-3">
                            <div className="mt-2">
                              <p
                                className="mb-0 btn-filters"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                title="Refresh"
                                style={{ marginRight: "9px" }}
                                onClick={RefreshHandle}
                              >
                                <span>
                                  <RefreshCcw />
                                </span>
                              </p>
                            </div>
                            <div style={{ marginRight: "9px" }}>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                value={historysearch}
                                onChange={(e) =>
                                  setHistorysearch(e.target.value)
                                }
                              />
                            </div>
                            <div style={{ marginRight: "9px" }}>
                              <input
                                type="month"
                                className="form-control"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                              />
                            </div>
                            <ExportToExcel
                              className="btn btn-primary"
                              apiData={ForGetCSV}
                              fileName={"Total InActive"}
                            />
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <FullDataTable columns={columns} data={history} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AllClients;
