import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddForm from "./FormikForm";
import { AddPanles, Panle_Type } from "../ReduxStore/Slice/Panles/PanleSlice";
import { useFormik } from "formik";
import Swal from 'sweetalert2'
import { useEffect, useState } from "react";






const AddClient = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getPanleType, setPanleType] = useState({
    loading: true,
    data: []
  })



  const userName = JSON.parse(localStorage.getItem("user_details")).FullName;



  const formik = useFormik({
    initialValues: {
      Domain_name: "",
      Port: "",
      Database_Ip: "",
      Panel_Name: "",
      Panle_Type: "",
      Owner_Email: '',
      Owner_name: '',
      Owner_Phone: '',
      RDP: '',

    },
    validate: (values) => {
      let errors = {};
      if (!values.Domain_name && formik.touched.Domain_name) {
        errors.Domain_name = "Enter Domain Name"
      }
      // if (!values.Port && formik.touched.Port) {
      //   errors.Port = "Enter Port Number"
      // }
      if (!values.Database_Ip && formik.touched.Database_Ip) {
        errors.Database_Ip = "Enter Database Ip"
      }
      if (!values.Panel_Name && formik.touched.Panel_Name) {
        errors.Panel_Name = "Enter Panel Name"
      }

      // if (!values.Owner_name && formik.touched.Owner_name) {
      //   errors.Owner_name = "Enter Owner Name"
      // }
      // if (!values.Owner_Phone && formik.touched.Owner_Phone) {
      //   errors.Owner_Phone = "Enter Owner Phone no"
      // }
      else if (values.Owner_Phone && values.Owner_Phone.length < 10) {
        errors.Owner_Phone = "Number Must be 10 digit";
      }
  
      if (!values.Panle_Type && formik.touched.Panle_Type) {
        errors.Panle_Type = "Select any one Panel type"
      }
      if (!values.RDP && formik.touched.RDP) {
        errors.RDP = "RDP is Require"
      }

      return errors;
    },
    onSubmit: async (values) => {
      const req = {
        Domain_name: values.Domain_name,
        Port: values.Port,
        Database_Ip: values.Database_Ip,
        Panel_Name: values.Panel_Name,
        Panle_Type: values.Panle_Type,
        Owner_Email: values.Owner_Email,
        Owner_name: values.Owner_name,
        Owner_Phone: values.Owner_Phone,
        userName: userName,
        RDP: values.RDP,

      };
      await dispatch(AddPanles(req))
        .unwrap()
        .then(async (response) => {
          if (response.status) {
            Swal.fire({
              title: "Panel  Add",
              text: "Panel  Added successfull...",
              icon: "success",
              timer: 1500,
              timerProgressBar: true,

            });
            setTimeout(() => {
              navigate("/panle/allpanles");
            }, 1500);
          } else {
            console.log(response.data)
            Swal.fire({
              title: response.msg,
              text: response.data,
              icon: "error",
              timer: 1500,
              timerProgressBar: true,

            });
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
  });


  const fields = [

    {
      name: "Domain_name",
      label: "Domain Name",
      type: "text",
      label_size: 6,
      col_size: 6,
      disable: false,
    },
    {
      name: "Port",
      label: "Port",
      type: "text3",
      label_size: 12,
      col_size: 6,
      disable: false,
    },
    {
      name: "Database_Ip",
      label: "Database IP",
      type: "text",
      label_size: 12,
      col_size: 6,
      disable: false,
    },
    {
      name: "Panel_Name",
      label: "Panel Name",
      type: "text",
      label_size: 12,
      col_size: 6,
      disable: false,
    },
    {
      name: "RDP",
      label: "RDP",
      type: "text3",
      label_size: 12,
      col_size: 6,
      disable: false,
    },

    {
      name: "Panle_Type",
      label: "Panel  Type",
      type: "select",
      options: getPanleType.data && getPanleType.data.map((item) => ({
        label: item.Panle_Type,
        value: item.Panle_Type,
      })),

      label_size: 12,
      col_size: 6,
      disable: false,
    },
    {
      name: "Owner_name",
      label: "Owner Name",
      type: "text",
      label_size: 12,
      col_size: 6,
      disable: false,
    },
    {
      name: "Owner_Phone",
      label: "Owner Phone",
      type: "text3",
      label_size: 12,
      col_size: 6,
      disable: false,
    },
    {
      name: "Owner_Email",
      label: "Owner Email",
      type: "text",
      label_size: 12,
      col_size: 6,
      disable: false,
    },

  ];

  const getPanle_Type = async () => {
    try {
      await dispatch(Panle_Type()).unwrap()
        .then((response) => {
          if (response.status) {
            setPanleType({
              loading: false,
              data: response.data
            })

          }
          else {
            setPanleType({
              loading: false,
              data: []
            })
          }
        })

    }
    catch (error) {
      console.log("Error in finding the Panel tpye", error)
    }
  }

  useEffect(() => {
    getPanle_Type()
  }, [])




  return (

    <>
      <div className="m-5">
        <AddForm
          fields={fields.filter(
            (field) => !field.showWhen || field.showWhen(formik.values)
          )}
          page_title="Add New Panel "
          btn_name="Add Panel "
          btn_name1="Cancel"
          formik={formik}
          btn_name1_route={"/panle/allpanles"}
        />
      </div>
    </>
  );
};
export default AddClient;
