import axios from "axios";
import * as Config from "../Utils/Config";

  

// LOGIN-USER
export async function GET_ALL_PANLES(data) {
    try {
        const res = await axios.post(`${Config.base_url}panle/getall`, data, {
            data: {},
        })
        return await res?.data;

        
    }
    catch (err) {
        return await err;

    }

}

export async function DELETE_PANLE(data) {
    try {
        const res = await axios.post(`${Config.base_url}panle/delete`, data, {
            data: {},
        })
        return await res?.data;

        
    }
    catch (err) {
        return await err;

    }

}

export async function ADD_PANLES(data) {
    try {
        const res = await axios.post(`${Config.base_url}add/panle`, data, {
            data: {},
        })
        return await res?.data;

        
    }
    catch (err) {
        return await err;

    }

}
export async function GET_HISTORY(data) {
    try {
        const res = await axios.post(`${Config.base_url}history/getall`, data, {
            data: {},
        })
        return await res?.data;

        
    }
    catch (err) {
        return await err;

    }

}

export async function EDIT_PANLES(data) {
    try {
        const res = await axios.post(`${Config.base_url}panle/edit`, data, {
            data: {},
        })
        return await res?.data;

        
    }
    catch (err) {
        return await err;

    }

}

export async function UPDATE_USERACTIVE_STATUS(data) {
    try {
        const res = await axios.post(`${Config.base_url}update/useractive/status`, data, {
            data: {},
        })
        return await res?.data;

        
    }
    catch (err) {
        return await err;

    }

}

export async function PANLE_TYPE(data) {
    try {
        const res = await axios.post(`${Config.base_url}panle/type`, data, {
            data: {},
        })
        return await res?.data;

        
    }
    catch (err) {
        return await err;

    }

}

export async function UPDATE_ADMIN_INFO(data) {
    try {
        const res = await axios.post(`${Config.base_url}update/admininfo`, data, {
            data: {},
        })
        return await res?.data;

        
    }
    catch (err) {
        return await err;

    }

}

// paginantion system

export async function history1(data) {
    try {
        const res = await axios.post(`${Config.base_url}update/history1`, data, {
            data: {},
        })
        return await res?.data;

        
    }
    catch (err) {
        return await err;

    }

}


// historybyid

export async function historybyname(data) {
    try {
        const res = await axios.post(`${Config.base_url}update/historybyPanelname`, data, {
            data: {},
        })
        return await res?.data;

        
    }
    catch (err) {
        return await err;

    }

}

export async function Addlead(data) {
    try {
        const res = await axios.post(`${Config.base_url}add/lead`, data, {
            data: {},
        })
        return await res?.data;

        
    }
    catch (err) {
        return await err;

    }

}


export async function Getallleads(data) {
    try {
        const res = await axios.get(`${Config.base_url}geAllLeads`, data, {
            data: {},
        })
        return await res?.data;

        
    }
    catch (err) {
        return await err;

    }

}


// update lead data 

export async function Updateleaduser(data) {
    try {
        const res = await axios.post(`${Config.base_url}updateLeadUser`, data, {
            data: {},
        })
        return await res?.data;

        
    }
    catch (err) {
        return await err;

    }

}


// delete leads user


export async function Deleteleadsuser(data) {
    try {
        const res = await axios.post(`${Config.base_url}DeleteLeadUser`, data, {
            data: {},
        })
        return await res?.data;

        
    }
    catch (err) {
        return await err;

    }

}


// get leads history


export async function Getleadhistory(data) {
    try {
        const res = await axios.get(`${Config.base_url}getLeadshistory`, data, {
            data: {},
        })
        return await res?.data;

        
    }
    catch (err) {
        return await err;

    }

}


// get per user leads  history

export async function GetperUserleads(data) {
    try {
        const res = await axios.post(`${Config.base_url}getPerUserhistory`, data, {
            data: {},
        })
        return await res?.data;

        
    }
    catch (err) {
        return await err;

    }

}