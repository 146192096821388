import './App.css';
import React from 'react';

import Main_Route from './Main_Route/Main_Route';



function App() {


  return (
    <>
  
        <Main_Route />
    
    </>
  );
}

export default App;




