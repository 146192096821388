import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import FullDataTable from "./FulldataTable";
import { get_history1, Get_History} from "../ReduxStore/Slice/Panles/PanleSlice";
import { RefreshCcw } from "lucide-react";
import { fDateTime } from "../Utils/Date_formet";
// import PaginationDatatable from "./PaginationDatatable";
import FulldataTable from "./FulldataTable";


const AllClients = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [inputSearch, setInputSearch] = useState("");
  const [history, setHistory] = useState({
    loading: true,
    data: [],
  });

  const [error, setError] = useState(null);
  const [date, setDate] = useState("");
  // const [page, setPage] = useState(1);
  // const limit = 10;
  // const [totalPages, setTotalPages] = useState(1);

  // const fetchData1 = async (page, limit) => {
  //   setError(null);
  //   try {
  //     const response = await dispatch(get_history1({ page, limit })).unwrap();
  //     if (response.status) {
  //       setHistory({
  //         loading: false,
  //         data: response.data,
  //         pagination: response.pagination,
  //       });
  //       setTotalPages(response.pagination.totalPages);
  //     } else {
  //       setHistory({
  //         loading: false,
  //         data: [],
  //         pagination: {},
  //       });
  //     }
  //   } catch (err) {
  //     console.log("Error in fetching History", err);
  //     setError("Failed to load history data. Please try again later.");
  //     setHistory({
  //       loading: false,
  //       data: [],
  //       pagination: {},
  //     });
  //   }
  // };




   
  const fetchData = async () => {
    setError(null);
    try {
      const response = await dispatch(Get_History()).unwrap();
      if (response.status) {
        setHistory({
          loading: false,
          data: response.data,
        });
        
      } else {
        setHistory({
          loading: false,
          data: [],
          pagination: {},
        });
      }
    } catch (err) {
      console.log("Error in fetching History", err);
      setError("Failed to load history data. Please try again later.");
      setHistory({
        loading: false,
        data: [],
      });
    }
  };





  const RefreshHandle = () => {
    setDate("");
    setInputSearch("");
    
  };

  const columns1 = useMemo(
    () => [
      {
        accessorKey: "No.",
        header: "No.",
        muiTableHeadCellProps: { sx: { color: "green" } },
        size: 20,
        Cell: ({ cell, index }) => <strong>{cell.row.index + 1}</strong>,
        headerStyle: { width: "10px" },
      },
      {
        accessorKey: "Panle_name",
        header: "Panle_name",
        size: 200,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
      },
      {
        accessorKey: "Name",
        header: "Name",
        size: 200,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
        headerStyle: { width: "160px" },
      },
      {
        accessorKey: "Message",
        header: "Message",
        size: 300,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
        headerStyle: { width: "300px" },
      },
      {
        accessorKey: "createdAt",
        header: "Time",
        size: 200,
        muiTableHeadCellProps: { sx: { color: "green" } },
        Cell: ({ renderedCellValue }) => (
          <strong>
            {renderedCellValue ? fDateTime(renderedCellValue) : "-"}
          </strong>
        ),
      },
    ],
    []
  );

  const filteredData = useMemo(() => {
    let filtered = history.data;

    if (inputSearch) {
      filtered = filtered.filter(
        (item) =>
          item.Name.toLowerCase().includes(inputSearch.toLowerCase()) ||
          item.Message.toLowerCase().includes(inputSearch.toLowerCase()) ||
          item.Panle_name.toLowerCase().includes(inputSearch.toLowerCase())
      );
    }

    if (date) {
      filtered = filtered.filter(
        (item) => item.createdAt && item.createdAt.includes(date)
      );
    }

    return filtered;
  }, [inputSearch, history.data, date]);

  useEffect(() => {
    fetchData();
  }, [inputSearch]);



  


  return (
    <div className="content container-fluid" data-aos="fade-left">
      <div className="card mt-5">
        <div className="card-header" style={{ backgroundColor: "#b2c6ed" }}>
          <div className="row align-items-center">
            <div className="col">
              <h5 className="card-title mb-0">
                <i className="pe-2 fa-solid fa-users"></i>
                History
              </h5>
            </div>
            <div className="col-auto">
              <div className="list-btn">
                <ul className="filter-list mb-0 d-flex gap-3">
                  <div className="mt-2">
                    <p
                      className="mb-0 btn-filters"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Refresh"
                      style={{ marginRight: "9px" }}
                      onClick={RefreshHandle}
                    >
                      <span>
                        <RefreshCcw />
                      </span>
                    </p>
                  </div>
                  <div style={{ marginRight: "9px" }}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      value={inputSearch}
                      onChange={(e) => setInputSearch(e.target.value)}
                    />
                  </div>
                  <div style={{ marginRight: "9px" }}>
                    <input
                      type="date"
                      className="form-control"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      type="month"
                      className="form-control"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          {error && <div className="alert alert-danger">{error}</div>}
          {history.loading ? (
            <div>Loading...</div>
          ) : (
            <>
              {/* <PaginationDatatable
                columns={columns1}
                data={filteredData}
                totalPages={totalPages}
                page={page}
                setPage={setPage}
              /> */}

              <FulldataTable columns={columns1} data={filteredData} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllClients;
