import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import AddForm from "./FormikForm";
import { Addleads, Panle_Type } from "../ReduxStore/Slice/Panles/PanleSlice";





const AddClient = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getPanleType, setPanleType] = useState({
    loading: true,
    data: [],
  });

  const userName = JSON.parse(localStorage.getItem("user_details")).FullName;

  const formik = useFormik({
    initialValues: {
      Panel_Name: "",
      Panle_Type: "",
      Owner_Email: '',
      Owner_name: '',
      Owner_Phone: '',
      Refer: '',
      status: '',

    },
    validate: (values) => {
      let errors = {};
      if (!values.Owner_name && formik.touched.Owner_name) {
        errors.Owner_name = "Enter Owner Name";
      }
      if (!values.Owner_Phone && formik.touched.Owner_Phone) {
        errors.Owner_Phone = "Enter Owner Phone Number";
      }

      if (!values.Panel_Name && formik.touched.Panel_Name) {
        errors.Panel_Name = "Enter Panel Name";
      }

      if (!values.Refer && formik.touched.Refer) {
        errors.Refer = "Enter Refer Name";
      }

      if (!values.status && formik.touched.status) {
        errors.status = "Enter status Name";
      }

      if (!values.Panle_Type && formik.touched.Panle_Type) {
        errors.Panle_Type = "Select any one Panel type";
      }

      return errors;
    },
    onSubmit: async (values) => {
      const req = {
        Panel_Name: values.Panel_Name,
        Panle_Type: values.Panle_Type,
        Owner_Email: values.Owner_Email,
        Owner_name: values.Owner_name,
        Owner_Phone: values.Owner_Phone,
        Refer: values.Refer,
        userName: userName,
        status: values.status,
      };

      await dispatch(Addleads(req))
        .unwrap()
        .then((response) => {
          if (response.status) {
            Swal.fire({
              title: "Panel Added",
              text: "Panel Added successfully...",
              icon: "success",
              timer: 1500,
              timerProgressBar: true,
            });
            setTimeout(() => {
              navigate("/panle/Leads");
            }, 1500);
          } else {
            Swal.fire({
              title: response.msg,
              text: response.data,
              icon: "error",
              timer: 1500,
              timerProgressBar: true,
            });
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
  });





  const fields = [
    {
      name: "Owner_name",
      label: "Owner Name",
      type: "text",
      label_size: 12,
      col_size: 6,
      disable: false,
    },
    {
      name: "Owner_Phone",
      label: "Owner Phone",
      type: "text3",
      label_size: 12,
      col_size: 6,
      disable: false,
    },
    {
      name: "Owner_Email",
      label: "Owner Email",
      type: "text",
      label_size: 12,
      col_size: 6,
      disable: false,
    },
    {
      name: "Panel_Name",
      label: "Panel Name",
      type: "text",
      label_size: 12,
      col_size: 6,
      disable: false,
    },
    {
      name: "Refer",
      label: "Refer",
      type: "text",
      label_size: 12,
      col_size: 6,
      disable: false,
    },
    {
      name: "Panle_Type",
      label: "Panel Type",
      type: "select",
      options: getPanleType.data.map((item) => ({
        label: item.Panle_Type,
        value: item.Panle_Type,
      })),
      label_size: 12,
      col_size: 6,
      disable: false,
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      options: [

        {
          label: "Pending",
          value: "Pending",
        },
        {
          label: "Complete",
          value: "Complete",
        },
        {
          label: "Reject",
          value: "Reject",
        },
      ],
      label_size: 12,
      col_size: 6,
      disable: false,
    },
  ];





  const getPanle_Type = async () => {
    try {
      const response = await dispatch(Panle_Type()).unwrap();
      if (response.status) {
        setPanleType({
          loading: false,
          data: response.data,
        });
      } else {
        setPanleType({
          loading: false,
          data: [],
        });
      }
    } catch (error) {
      console.log("Error in finding the Panel type", error);
    }
  };



  
  useEffect(() => {
    getPanle_Type();
  }, []);

  return (
    <div className="m-5">
      <AddForm
        fields={fields.filter((field) => !field.showWhen || field.showWhen(formik.values))}
        page_title="Add New Lead"
        btn_name="Add Leads"
        btn_name1="Cancel"
        formik={formik}
        btn_name1_route="/panle/Leads"
      />
    </div>
  );
};

export default AddClient;
